<template>
  <div class="page-home">
    <page-header />

    <div>
      <!-- 显示轮播图占位 -->
      <div v-if="carouselLoading">
        <v-skeleton-loader
          :loading="carouselLoading"
          height="480px"
          type="image"
          class="w-100 mb-14"
        >
        </v-skeleton-loader>
      </div>
      <div class="page-home-top d-none d-sm-flex">
        <img
          v-if="imgList && imgList.length > 0"
          :src="imgList?.[0]?.imageUrl"
          class="w-100"
          style="opacity: 0"
        />
        <v-carousel
          cycle
          hide-delimiter-background
          :interval="delayTime"
          v-model="model"
        >
          <v-carousel-item
            :selected-class="item.linkUrl ? 'link' : ''"
            v-for="item in imgList"
            :key="item.id"
            @click="jumpFn(item.linkUrl)"
            cover
          >
            <v-img :src="item.imageUrl" contain class="w-100 h-100"></v-img>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="page-home-top mobile-terminal d-flex d-sm-none">
        <img
          :src="imgList?.[0]?.imageMobUrl"
          class="w-100"
          style="opacity: 0"
        />
        <v-carousel
          cycle
          hide-delimiter-background
          :interval="delayTime"
          v-model="model"
        >
          <v-carousel-item
            v-for="item in imgList"
            :key="item.id"
            @click="jumpFn(item.linkUrl)"
            cover
          >
            <v-img :src="item.imageMobUrl" contain class="w-100 h-100"></v-img>
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="page-home-cont store-container sp-container">
        <h2 class="page-home-cont-tit mt-16 mb-5 d-none d-sm-flex fs-24">
          Best Sellers
        </h2>
        <h2 class="page-home-cont-tit mt-8 mb-5 d-flex d-sm-none fs-20">
          Best Sellers
        </h2>
        <v-row class="best-sellers">
          <template v-for="(item, i) in 15" :key="i" v-if="productsLoading">
            <v-col cols="6" xs="6" sm="4" md="3" lg="2" xl="2" xxl="2">
              <v-skeleton-loader
                :loading="productsLoading"
                height="250px"
                type="image,list-item-three-line,"
                class="w-100 mb-14"
              >
              </v-skeleton-loader>
            </v-col>
          </template>
          <template v-for="(item, i) in products" :key="i">
            <v-col
              cols="6"
              xs="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              xxl="2"
              class="mt-2 pa0"
            >
              <shopmall-product-item :productInfo="item" is-index-best />
            </v-col>
          </template>
        </v-row>
        <h2 class="page-home-cont-tit mt-16 mb-5 d-none d-sm-flex fs-24">
          New Arrivals
        </h2>
        <h2 class="page-home-cont-tit mt-8 mb-5 d-flex d-sm-none fs-20">
          New Arrivals
        </h2>
        <v-row class="mb-8 new-arrivals">
          <template v-for="(item, i) in 15" :key="i" v-if="newArrivalLoading">
            <v-col cols="6" xs="6" sm="4" md="3" lg="2" xl="2" xxl="2">
              <v-skeleton-loader
                :loading="newArrivalLoading"
                height="250px"
                type="image,list-item-three-line,"
                class="w-100 mb-14"
              >
              </v-skeleton-loader>
            </v-col>
          </template>
          <template v-for="(item, i) in newArrival" :key="i">
            <v-col
              cols="6"
              xs="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              xxl="2"
              class="mt-2 pa0"
            >
              <shopmall-product-item :productInfo="item" is-new-arrivals />
            </v-col>
          </template>
        </v-row>
        <h2
          class="page-home-cont-tit mb-8 mt-8 d-none d-sm-flex fs-24"
          v-if="categories.length || categoriesLoading"
        >
          Top Categories
        </h2>
        <h2
          class="page-home-cont-tit mb-8 mt-0 d-flex d-sm-none fs-20"
          v-if="categories.length || categoriesLoading"
        >
          Top Categories
        </h2>
        <div class="home-panle d-none d-sm-flex">
          <v-skeleton-loader
            v-for="item in 5"
            :loading="categoriesLoading"
            type="image"
            class="home-panle-item pa-3 mb-8"
            height="350px"
            v-if="categoriesLoading"
          >
          </v-skeleton-loader>
          <div
            class="home-panle-item pa-3 mb-8"
            v-for="(item, i) in categories"
            :key="i"
          >
            <div class="top-ategories" @click="jumpCategory(item)">
              <v-img
                :src="item.imageUrl + '?x-oss-process=image/resize,l_600'"
                class="align-end w-100"
                cover
              ></v-img>
            </div>
          </div>
        </div>
        <div class="home-panle home-panle-sm d-flex d-sm-none">
          <v-skeleton-loader
            v-for="item in 5"
            v-if="categoriesLoading"
            :loading="categoriesLoading"
            type="image"
            class="home-panle-item pa-3 mb-8"
            height="350px"
          >
          </v-skeleton-loader>
          <div
            class="home-panle-item pa-3 mb-8"
            v-for="(item, i) in categories"
            :key="i"
          >
            <div class="top-ategories" @click="jumpCategory(item)">
              <v-img
                :src="item.imageUrl + '?x-oss-process=image/resize,l_600'"
                class="align-end w-100"
                cover
              ></v-img>
            </div>
          </div>
        </div>
      </div>
      <v-img
        :src="$multiPlatform.bootomIcon + '?x-oss-process=image/resize,l_300'"
        width="100%"
      ></v-img>
    </div>
    <!-- 更新密码弹窗 -->
    <shopmall-dialog
      ref="pwdDialog"
      :show-confirm="false"
      :show-title="false"
      :max-width="500"
    >
      <div>
        <shopmall-updatepassword
          :elevation="0"
          dialog
          @skipFn="skip"
          :updatePasswordType="updatePasswordType"
        ></shopmall-updatepassword>
      </div>
    </shopmall-dialog>

    <page-footer></page-footer>
  </div>
</template>
<script setup scoped>
import { productInteractor, systemInteractor, authInteractor } from "~/core";
import { useProductStore, useUserStore } from "~/stores";
const { $multiPlatform } = useNuxtApp();
const category = useCategory();
const config = useRuntimeConfig();
const primaryColor = config.public.primaryColor;
const companyName = config.public.companyName;
// seo
useHead({
  title: `${companyName} - Customized design and on-demand printing`,
  meta: [
    {
      name: "description",
      content: `At ${companyName}, we provide an extensive selection of custom-made promotional products known for their highly competitive pricing and industry-leading turnaround times. Explore our product catalog, which includes notebooks, trade show items, gadgets, giveaways, and promotional merchandise.`
    },
    {
      name: "keywords",
      content: `${companyName}, Rivers, Promo, promotional, customized, customizable, trade show, promo items, product sourcing, giveaway, office supplies, toys, keychains, stress balls, bags, mugs, cups, accessories, fitness, sweatbands, hats, caps, phone accessories, USB, car, auto accessories, logo, desktop, tools, gadgets, party supplies, pet supplies, household items, Power Banks, Chargers, Smart Phone, PPE, Personal Protective Equipment, Protective Gear, Disposable Gloves, Alcohol Wipes, Face Mask`
    }
  ]
});
const updatePasswordType = ref(1); //1是商城老客户2是商城后台新增用户展示样式不同
const productStore = useProductStore();
const model = ref(0);
const { $Utils, $BuryIntersectionObserver } = useNuxtApp();
const userStore = useUserStore();
// 分类跳转
const jumpCategory = item => {
  productStore.setAccessories({
    id: item.categoryId,
    nameEn: item.categoryNameEn
  });
  category.setCategorysInfo(item);

  navigateTo(
    `/category/${$Utils.replaceSpacesWithDash(item.categoryNameEn, false)}`
  );
};
const delayTime = ref(5000);
definePageMeta({
  layout: false,
  middleware: ["auth"]
});

function showGoodDetail() {
  navigateTo({
    path: "/commodity/3"
  });
}
onMounted(() => {
  oneTimeLogin();
  getImgList();
  getList();
  getNewArrival();
  getCategoryList();
  onBroadCastChannelMessage();
  nextTick(() => {
    if (userStore?.userInfo?.type == 4 && userStore.updatePasswordBol) {
      updatePasswordType.value = 2;
      pwdDialog.value.show();
      if (userStore.updatePasswordBol) userStore.setUpdatePasswordBol();
    }
  });
});
const { $Confirm, } = useNuxtApp();
const showLoginStatusChange = ref(true);
const onBroadCastChannelMessage = () => {
  try {
    const BC = new BroadcastChannel("LOGIN");
    BC.onmessage = () => {
      if (showLoginStatusChange.value) {
        $Confirm({
          title:
            "<p>You have switched to another account.</p><p> Please refresh to continue.</p>",
          showCancelButton: false
        }).then(result => {
          if (result?.isConfirmed) {
            window.location.reload();
          }
        });
      }
    };
  } catch (error) {
    if (typeof BroadcastChannel === "undefined") {
      const browserInfo = $Utils.getBrowserInfo();
      throw new Error(`BroadcastChannel API不兼容: ${browserInfo}`);
    } else {
      throw error;
    }
  }
};
const products = ref([]);
const newArrival = ref([]);
const imgList = ref([]);


const categories = ref([]);
// 取销量好的产品列表
const productsLoading = ref(true);
const getList = async () => {
  if (!productInteractor) return;
  const [res, err] = await productInteractor.listBestSellersApi();
  if (res) {
    products.value = res;
    setTimeout(() => {
      new $BuryIntersectionObserver('.best-sellers .sp-product-item-wrapper')
    },1000)
  } else {
    products.value = [];
  }
  productsLoading.value = false;
};

// newArrival产品列表
const newArrivalLoading = ref(true);
const getNewArrival = async () => {
  if (!productInteractor) return;
  const [res, err] = await productInteractor.listNewArrivalApi();
  if (res) {
    newArrival.value = res;
    setTimeout(() => {
      new $BuryIntersectionObserver('.new-arrivals .sp-product-item-wrapper')
    },1000)
  } else {
    newArrival.value = [];
  }
  newArrivalLoading.value = false;
};
// 获取类目
const categoriesLoading = ref(true);
const getCategoryList = async () => {
  const [res, err] = await systemInteractor.listByTenantIdApi();
  if (res) {
    categories.value = res;
    
  } else {
    categories.value = [];
  }
  categoriesLoading.value = false;
};
// 获取轮播图列表
const carouselLoading = ref(true);

const getImgList = async () => {
  const [res, err] = await systemInteractor.listByTenantIdImgApi();
  if (res) {
    imgList.value = res;
    // 获取到数据后延时初始化曝光率
    setTimeout(() => {
      new $BuryIntersectionObserver('.v-window-item')
    },300)
  } else {
    imgList.value = [];
  }
  carouselLoading.value = false;
};
// 点击轮播跳转

const { $Buryingpoint, } = useNuxtApp();
const jumpFn = url => {
  if (!url) return;
  // 埋点轮播图点击事件
  $Buryingpoint.bannerClick(url);
  window.open(`${url}?t=ba`, "_blank");
};

const pwdDialog = ref(null);

const auth = useAuth();
const cart = useCart();
const oneTimeLogin = async () => {
  let { email, param, type } = $Utils.getQueryParams(window.location.href);
  if (type === "onetimelogin") {
    let isSameUser = auth.isSameLoginUser({ email });
    // 判断是否登录
    let isLoggedIn = await auth.isLoggedIn();
    if (param) {
      const [userInfo, err] = await authInteractor.emailLoginMallApi(param);
      let [checkRes, checkErr] = await authInteractor.checkEmailApi(email);

      if (userInfo && userInfo?.type === 0) {
        // 未失效场景
        // 正常登录
        userStore.setUserInfo({
          ...userInfo
        });
        userStore.setAccessToken(userInfo.accessToken);
        userStore.setRefreshToken(userInfo.refreshToken);
        // 合并购物车数据
        cart.initCartData();
        // 获取用户详情
        auth.setUserInfo();

        // 显示登录弹窗
        if (checkRes?.type == 3) {
          //未设置密码在显示设置密码弹窗
          pwdDialog.value && pwdDialog.value.show();
        }
        userStore.setOneTimeLoginEmail("");
        // 已登录的情况下并且不是相同用户
        if (!isSameUser && isLoggedIn) {
          if (typeof BroadcastChannel === "undefined") return;
          const BC = new BroadcastChannel("LOGIN");
          showLoginStatusChange.value = false;
          BC.postMessage("login-user-change");
        }
      } else if (userInfo && userInfo.type === 3) {
        // 判断是否相同账号
        if (!isSameUser) {
          // 不是相同账号则返回到重新登录页面
          // 链接失效，需要重新登录
          userStore.setOneTimeLoginEmail(email);
          // 跳转到登录页
          navigateTo("/account/signin");
        } else {
          // 相同账号
          if (isLoggedIn) {
            // 相同账号则直接显示登录弹窗
            if (checkRes.type == 3) {
              pwdDialog.value && pwdDialog.value.show();
            }
          } else {
            navigateTo("/account/signin");
          }
        }
      }
    }
  }
};

// 跳过更新密码
const skip = async () => {
  pwdDialog.value && pwdDialog.value.show();
  if (updatePasswordType.value === 1) {
    const [res, err] = await authInteractor.customerDetailApi(
      userStore.userInfo.customerId
    );
    if (res) {
      userStore.setUserInfo({
        status: res.status
      });
    }
    navigateTo("/");
  }
};
</script>
<style lang="scss">
html {
  overflow: auto;
}
.page-home {
  width: 100%;
  position: relative;
  min-height: 100vh;
  height: 100%;
  background-color: white;
  --primary-color: v-bind(primaryColor);
  .top-ategories {
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.035);
    }
  }
  .home-panle {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
    .home-panle-item {
      width: 20%;
    }
    &.home-panle-sm {
      .home-panle-item {
        width: 50%;
      }
    }
  }
  .contac-us-icon {
    position: fixed;
    right: 25px;
    bottom: 140px;
    z-index: 999;
  }
  .page-home-top {
    position: relative;
    .v-carousel {
      height: 100% !important;
      position: absolute;
      z-index: 2;
      .v-carousel-item {
        &.link {
          cursor: pointer;
        }
      }
      .v-carousel__controls {
        .v-btn {
          .v-btn__content {
            .mdi-circle {
              color: #fff;
            }
          }
          .v-btn__overlay {
            background: var(--primary-color);
          }
          &.v-btn--active {
            .v-btn__content {
              .mdi-circle {
                color: var(--primary-color);
              }
            }
          }
        }
      }
    }
    &.mobile-terminal {
      .v-carousel {
        .v-window__controls {
          .v-btn {
            display: none;
          }
        }
        .v-btn {
          width: 10px;
          height: 10px;
          .v-btn__content {
            i {
              font-size: 8px;
            }
          }
        }
        .v-carousel__controls {
          height: 14px;
          .v-btn {
            width: 5px;
            height: 5px;
            margin: 0 2px;
            .v-btn__content {
              width: 5px;
              height: 5px;
              i {
                font-size: 5px;
              }
            }
          }
        }
      }
    }
    .page-home-top-panel {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: linear-gradient(
        to right,
        rgba(85, 85, 85, 0.6) 20%,
        rgba(85, 85, 85, 0.3) 40%,
        rgba(85, 85, 85, 0.1) 50%,
        rgba(85, 85, 85, 0) 100%
      );
      .page-home-top-panel-item {
        height: 100%;
      }
    }
  }
  .page-home-cont {
    .page-home-cont-tit {
      line-height: 24px;
      color: #1a1a1a;
      position: relative;
    }
    .categories {
      .card-title {
        font-size: 28px;
        color: #fff;
        text-align: center;
        height: 82px;
        line-height: 82px;
        background-color: rgba(2, 167, 240, 0.6);
      }
    }
  }
  :deep(.min-height-carouse) {
    min-height: 356px;
  }
}
.v-skeleton-loader__image {
  height: 100% !important;
}
</style>
